/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line
import { useEffect, useState, useContext } from "react";
import { css, jsx } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { SplitFactory, SplitTreatments } from "@splitsoftware/splitio-react";
import { Flex, Link, theme, Toast } from "@xometry/xometry_loft";
import * as Sentry from "@sentry/react";
import { FullStory } from "@fullstory/browser";
import { prismicClient } from "../../apollo";
/** @jsx jsx */
import { MarketingCards } from "../MarketingCards";
import PersonalizedMarketingCards from "../MarketingCards/PersonalizedMarketingCards";
import { QuoteWizardWithRequestQuote } from "../QuoteWizard";
import {
  topWrapper,
  errorWrapper,
  leftColumn,
  mainGrid,
  rightColumn,
  specialOffersText,
  welcomeText,
  subtitle,
  adsContainer,
  TabContainer,
  tableContainer,
} from "./App.style";
import { lightPanel } from "../../index.style";
import {
  GetLoggedInUserQuery,
  GetLoggedInUserQueryResult,
  GetUserCarbonOffsetQuery,
  GetUsersOrganizationsByIdentityQueryResult,
  Maybe,
} from "../../schema/generated/schema";
import { namedPageView, genericTrack, track } from "../../utils/analytics";
import * as Banners from "../NotificationBanner/bannerRegistry";
import {
  mktgCardsPersonalizationFeature,
  getSplitIOConfig,
} from "../../utils/splitIO";
import {
  origins,
  enterpriseWorkatoApiDomain,
  enterpriseWorkatoApiKey,
  SENTRY_DSN,
} from "../../utils/constants";
import ScriptLoader from "../../utils/ScriptLoader";
import CarbonOffset from "../CarbonOffset";
import { PunchOutContext } from "../PunchOutContext";
import AnonymousQuoteStart from "../AnonymousQuoteStart";
import { addImportDependencies } from "../../utils/lazyLoad";
import QuoteWithoutCADPanel from "../QuoteWithoutCADPanel/QuoteWithoutCADPanel";
import {
  getDotNeutralData,
  getLowerCaseEmailDomain,
} from "../../utils/helpers";
import RecentOrders from "../RemoteComponents/RecentOrders/RecentOrders";
import { QuoteListComponent } from "../RemoteComponents/QuoteList/QuoteListComponent";
import AnnouncementBanner from "../AnnouncementBanner";
import ContextSwitcher from "../ContextSwitcher/ContextSwitcher";
import TeamspaceBanner from "../TeamspaceBanner";
import { LazyTabView } from "../LazyTabView/LazyTabView";
import RecentQuotes from "../RemoteComponents/RecentQuotes/RecentQuotes";
import RecentTools from "../RemoteComponents/RecentTools/RecentTools";

declare global {
  interface Window {
    CustomerTopNav: any;
    repAttemptCounter: number;
  }
}

const ErrorState = () => (
  <div css={errorWrapper}>
    <div className="errorPanel">
      <div className="errorText">
        <h1>Unexpected Error</h1>
        <p> Please refresh the page to try again.</p>
      </div>
    </div>
  </div>
);

const App = () => {
  const { punchOut, punchoutSessionId } = useContext(PunchOutContext);

  useEffect(() => {
    namedPageView("Customer Home", "Instant Quote Funnel");
  }, []);

  const [componentLoaded, setComponentLoaded] = useState(false);
  const [showAccountReps, setShowAccountReps] = useState(false);
  const [activeTableTab, setActiveTableTab] = useState<number>(1); // 0 for Quotes, 1 for Orders
  const [shareError, setShareError] = useState(false);
  const isSSR = typeof window === "undefined";

  if (!isSSR) {
    addImportDependencies();
  }

  useEffect(() => {
    async function loadComponent() {
      const loader = new ScriptLoader(
        `${origins.home}/quoting/components/customer-top-nav/component.js`
      );
      const loadState: boolean = await loader.load();
      setComponentLoaded(loadState);
    }
    loadComponent();
  }, []);

  let TopNav: any;
  let Footer;
  let AccountReps;
  if (componentLoaded && window.CustomerTopNav) {
    TopNav = window.CustomerTopNav.default;
    Footer = window.CustomerTopNav.Footer;
    AccountReps = window.CustomerTopNav.AccountReps || null;
  }

  const { loading, error, data } = useQuery<GetLoggedInUserQueryResult>(
    GetLoggedInUserQuery,
    {
      errorPolicy: "all",
    }
  );

  const loggedInUser = data?.me;

  const [segmentLoaded, setSegmentLoaded] = useState(false);
  const [usersOrgs, setUsersOrgs] = useState<
    GetUsersOrganizationsByIdentityQueryResult["getUsersOrganizationsByIdentity"]
  >([]);
  const maxRepAttempts = 120;
  const repAttemptInterval = 1000;

  const { data: getUserCarbonOffsetData } = useQuery(GetUserCarbonOffsetQuery, {
    variables: {
      userId: loggedInUser?.id || "",
    },
    skip: !loggedInUser?.id,
  });

  const [dotNeutralOffset, setDotNeutralOffset] = useState(0);

  if (SENTRY_DSN) {
    Sentry.setUser({
      id: loggedInUser?.id,
      email: loggedInUser?.emailAddress ?? "",
    });
    FullStory("setIdentity", {
      uid: loggedInUser?.id,
      properties: {
        displayName: `${loggedInUser?.firstName} ${loggedInUser?.lastName}`,
        email: loggedInUser?.emailAddress,
      },
    });
  }

  useEffect(() => {
    getDotNeutralData().then(responseData => {
      setDotNeutralOffset(responseData.offsets?.total_lbs);
    });
  }, []);

  useEffect(() => {
    window?.analytics?.ready(() => {
      setSegmentLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (segmentLoaded && loggedInUser) {
      const { id, firstName, lastName, emailAddress } = loggedInUser;
      if (window.analytics) {
        window.analytics.identify(id, {
          name: `${firstName || ""} ${lastName || ""}`.trim(),
          email: emailAddress,
          punchoutSessionId,
          punchout: punchOut ? 1 : 0,
        });
      }
      const qm = window.QuantumMetricAPI;
      if (qm) {
        qm.sendEvent(328, 0, id);
        qm.sendEvent(284, 38, emailAddress);
      }
      if (punchOut) {
        genericTrack("PunchOut Dashboard Page Visited", {
          name: `${firstName || ""} ${lastName || ""}`.trim(),
          email: emailAddress,
          punchoutSessionId,
        });
      }
    }
  }, [loggedInUser, segmentLoaded, punchOut, punchoutSessionId]);

  useEffect(() => {
    window.repAttemptCounter = 0;
    const repIntervalId = window.setInterval(() => {
      if (showAccountReps || window.repAttemptCounter > maxRepAttempts) {
        window.clearInterval(repIntervalId);
        return;
      }
      if (document.querySelectorAll("#account-rep-wrapper img").length) {
        setShowAccountReps(true);
        window.clearInterval(repIntervalId);
      }
      window.repAttemptCounter += 1;
    }, repAttemptInterval);
  }, []);

  /* Recent Orders/Quotes section (Tabs) */
  const tabs = [
    {
      header: "Quotes",
      content: (
        <div css={tableContainer}>
          <RecentQuotes
            onError={err => console.error(err?.message)}
            setShareError={setShareError}
            isTeamView={false}
          />
        </div>
      ),
    },
    {
      header: "Orders",
      content: (
        <div css={tableContainer}>
          <RecentOrders
            onError={err => console.error(err?.message)}
            setShareError={setShareError}
            isTeamView={false}
          />
        </div>
      ),
    },
    {
      header: "Tools",
      content: (
        <div css={tableContainer}>
          <RecentTools onError={err => console.error(err?.message)} />
        </div>
      ),
    },
  ];

  const activeTabSubtitle = (activeValue: number) => {
    if (activeValue === 0) {
      return " Quotes";
    }
    if (activeValue === 1) {
      return " Orders";
    }
    return " Tools";
  };
  const tabsSection = (
    <>
      <Flex css={subtitle}>
        <Flex>
          {" "}
          Recent
          {activeTabSubtitle(activeTableTab)}
        </Flex>
        <Toast
          kind="danger"
          scale={theme.scale.small}
          showDuration={5000}
          isOpen={shareError}
          onClose={() => {
            setShareError(false);
          }}
          marginBottom={0}
        >
          Select one or more orders that you want to share.
        </Toast>
      </Flex>

      <TabContainer>
        <LazyTabView
          tabs={tabs}
          activeTab={activeTableTab}
          setActiveTabInParent={tab => {
            setActiveTableTab(tab);
          }}
        />
      </TabContainer>
    </>
  );

  // We need to hide the Split banners for all SpaceX punchout users, which
  // we can really only determine by punchOut === true and email domain.
  const hideSplitBanners = (isPunchOut: boolean, emailAddress: Maybe<string>) =>
    isPunchOut && getLowerCaseEmailDomain(emailAddress) === "spacex.com";

  if (error) {
    console.error(error.message);

    return (
      <div
        css={css`
          min-height: 100vh;
        `}
      >
        {componentLoaded && TopNav && (
          <TopNav
            authBaseUrl={origins.auth}
            appRootBaseUrl={origins.webapp}
            partnerPortalBaseUrl={origins.work}
            wwwBaseUrl={origins.home}
            onLogin={() => {
              window.location.reload();
            }}
            onLogout={() => {
              window.location.href = `${origins.webapp}/login`;
            }}
            apiOrigin={origins.api}
            graphqlOrigin={`${origins.home}/api`}
          />
        )}

        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          <ErrorState />
        </div>
        {componentLoaded && Footer && origins && (
          <Footer wwwBaseUrl={origins.home} />
        )}
      </div>
    );
  }

  if (loading) return null;

  return (
    <SplitFactory config={getSplitIOConfig(loggedInUser?.id || "anonymous")}>
      <div
        css={css`
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        `}
      >
        <div css={topWrapper}>
          <AnnouncementBanner />
          {componentLoaded && TopNav && (
            <TopNav
              authBaseUrl={origins.auth}
              appRootBaseUrl={origins.webapp}
              partnerPortalBaseUrl={origins.work}
              wwwBaseUrl={origins.home}
              onLogin={() => {
                window.location.reload();
              }}
              onLogout={() => {
                window.location.href = `${origins.webapp}/login`;
              }}
              punchOut={punchOut}
              punchoutSessionId={punchoutSessionId}
            />
          )}
        </div>
        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          {loggedInUser ? (
            <>
              <ContextSwitcher
                loggedInUserId={loggedInUser.id}
                setUsersOrgs={setUsersOrgs}
              />
              <div css={mainGrid}>
                <div css={leftColumn}>
                  <div
                    css={welcomeText}
                    id="welcome-message"
                    className="pii-encrypt"
                  >
                    {loggedInUser.firstName
                      ? `Welcome back, ${loggedInUser.firstName}!`
                      : "Welcome Back!"}
                  </div>
                  <>
                    <QuoteWizardWithRequestQuote />
                    <div>
                      <div css={subtitle}>
                        <div>Pick Up Where You Left Off</div>
                        <div className="history-links">
                          <Link
                            href={`${origins.webapp}/quotes`}
                            onClick={() => {
                              track("View Recent Quotes");
                            }}
                          >
                            View All Quotes
                          </Link>
                        </div>
                      </div>
                      <QuoteListComponent
                        punchoutSessionId={punchoutSessionId}
                      />
                    </div>
                    {tabsSection}
                    {usersOrgs.length > 0 ? (
                      <QuoteWithoutCADPanel loggedInUser={loggedInUser} />
                    ) : (
                      <TeamspaceBanner />
                    )}
                    {hideSplitBanners(
                      punchOut,
                      loggedInUser?.emailAddress
                    ) ? null : (
                      <Banners.SplitIOConfigurable propsKey="pspSecondaryBannerProps" />
                    )}
                  </>
                </div>

                <div css={rightColumn}>
                  <div css={specialOffersText}>Special Offers + News</div>
                  {punchOut && AccountReps && (
                    <div
                      css={lightPanel}
                      id="account-rep-wrapper"
                      style={showAccountReps ? {} : { display: "none" }}
                    >
                      <AccountReps
                        email={loggedInUser.emailAddress}
                        enterpriseApiDomain={enterpriseWorkatoApiDomain}
                        enterpriseApiKey={enterpriseWorkatoApiKey}
                      />
                    </div>
                  )}
                  {hideSplitBanners(
                    punchOut,
                    loggedInUser?.emailAddress
                  ) ? null : (
                    <>
                      <SplitTreatments
                        names={[mktgCardsPersonalizationFeature]}
                      >
                        {({ treatments, isReady }): JSX.Element | null => {
                          return isReady &&
                            treatments[mktgCardsPersonalizationFeature]
                              .treatment === "on" ? (
                            <div css={adsContainer}>
                              <PersonalizedMarketingCards
                                loggedInUser={loggedInUser}
                              />
                            </div>
                          ) : (
                            <div css={adsContainer}>
                              <MarketingCards
                                prismicClient={prismicClient}
                                loggedInUser={loggedInUser}
                              />
                            </div>
                          );
                        }}
                      </SplitTreatments>

                      <CarbonOffset
                        userCarbonOffset={
                          getUserCarbonOffsetData?.getUserCarbonOffset
                        }
                        dotNeutralOffset={dotNeutralOffset}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <AnonymousQuoteStart />
          )}
        </div>

        {componentLoaded && Footer && origins && (
          <Footer wwwBaseUrl={origins.home} />
        )}
      </div>
    </SplitFactory>
  );
};

export default App;
